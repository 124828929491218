export default {
  locations: [
    {
      domain: 'com',
      iso: 'en',
      name: 'Global',
      extension: '.com',
      visible: false
    },
    {
      domain: 'uk',
      iso: 'en',
      name: 'English',
      extension: '.co.uk',
      visible: true
    },
    {
      domain: 'ba',
      iso: 'ba',
      name: 'Bosnia',
      extension: '.ba',
      visible: true
    },
    /*
    {
      domain: 'bg',
      iso: 'bg',
      name: 'Bulgaria',
      extension: '.bg',
      visible: true
    },
    */
    {
      domain: 'cy',
      iso: 'en-CY',
      name: 'Cyprus',
      extension: '.cy',
      visible: false
    },
    {
      domain: 'fi',
      iso: 'fi-FI',
      name: 'Finland',
      extension: '.fi',
      visible: true
    },
    {
      domain: 'hr',
      iso: 'hr-HR',
      name: 'Croatia',
      extension: '.hr',
      visible: true
    },
    {
      domain: 'hu',
      iso: 'hu',
      name: 'Hungary',
      extension: '.hu',
      visible: true
    },
    {
      domain: 'it',
      iso: 'it-IT',
      name: 'Italy',
      extension: '.it',
      visible: true
    },
    {
      domain: 'lt',
      iso: 'lt-LT',
      name: 'Lithuania',
      extension: '.lt',
      visible: true
    },
    {
      domain: 'lv',
      iso: 'lv-LV',
      name: 'Latvia',
      extension: '.lv',
      visible: true
    },
    {
      domain: 'me',
      iso: 'sr-Latn-ME',
      name: 'Montenegro',
      extension: '.me',
      visible: true
    },

    {
      domain: 'mk',
      iso: 'mk',
      name: 'Macedonia',
      extension: '.mk',
      visible: true
    },

    {
      domain: 'mt',
      iso: 'en-MT',
      name: 'Malta',
      extension: '.com.mt',
      visible: false
    },
    {
      domain: 'mx',
      iso: 'es-MX',
      name: 'Mexico',
      extension: '.mx',
      visible: true
    },
    {
      domain: 'pl',
      iso: 'pl-PL',
      name: 'Poland',
      extension: '.pl',
      visible: true
    },
    {
      domain: 're',
      iso: 'fr-RE',
      name: 'Reunion',
      extension: '.re',
      visible: true
    },
    {
      domain: 'rs',
      iso: 'rs',
      name: 'Serbia',
      extension: '.rs',
      visible: true
    },
    {
      domain: 'se',
      iso: 'sv-SE',
      name: 'Sweden',
      extension: '.se',
      visible: true
    },
    {
      domain: 'tr',
      iso: 'tr-TR',
      name: 'Turkey',
      extension: '.com.tr',
      visible: true
    },
    // Used for local development area
    {
      domain: 'test',
      iso: 'en',
      name: 'Global',
      extension: '.test',
      visible: false
    }
  ]
}
