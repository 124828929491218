<template>
  <b-col cols="12" class="contact-details">
    <b-row class="align-items-stretch justify-content-between mb-5">
      <section v-if="errored">
        <p class="m-0">{{ fmxSorry }}</p>
      </section>
      <template v-else>
        <div v-if="loading">
          <p class="m-0">{{ fmxLoading }}</p>
        </div>
        <template v-else>
          <b-col cols="12" lg="6" class="d-flex flex-column mb-3 mb-lg-0 px-5">
            <h2
              class="display-4 mb-5 text-center text-lg-left"
              v-if="tableLabel"
            >
              {{ tableLabel }}
            </h2>

            <div>
              <table class="table-borderless mb-0 w-100">
                <tr v-for="day in openingDays" :key="day.id">
                  <td>
                    <div class="pr-5 font-2rem">
                      {{ day.name }}
                    </div>
                  </td>
                  <td
                    class="font-weight-bold text-right font-2rem"
                    v-html="locationOpen"
                    v-if="day.is_24h === 1"
                  ></td>
                  <td
                    class="font-weight-bold text-right font-2rem"
                    v-html="locationClosed"
                    v-else-if="day.is_closed === 1"
                  ></td>
                  <td
                    class="font-weight-bold text-right font-2rem"
                    v-html="
                      new Date('1970-01-01 ' + day.open).toLocaleTimeString('en', {
                        hour12: acceptLanguage === 'en-US',
                        hour: 'numeric',
                        minute: 'numeric',
                      }) +
                      ' - ' +
                      new Date('1970-01-01 ' + day.close).toLocaleTimeString('en', {
                        hour12: acceptLanguage === 'en-US',
                        hour: 'numeric',
                        minute: 'numeric',
                      })"
                    v-else
                  ></td>
                </tr>
              </table>
            </div>

            <div class="d-flex flex-1 flex-column h-100 justify-content-end">
              <p class="font-weight-bold mt-5 mb-3" v-if="data.address_1">
                <span>{{ locationFullAddress }}</span>
              </p>

              <div class="align-items-center d-flex">
                <div>
                  <a
                    :href="mapLink"
                    target="_blank"
                    class="btn btn-primary btn-rounded btn-sm w-100"
                    v-if="mapLink"
                  >
                    {{ getDirectionsLabel }}
                  </a>
                  <a
                    :href="
                      'https://maps.google.com/maps?q=' +
                      data.latitude +
                      ',' +
                      data.longitude
                    "
                    target="_blank"
                    class="btn btn-primary sv-button btn-sm w-100"
                    v-else
                  >
                    {{ getDirectionsLabel }}
                  </a>
                </div>
                <div class="ml-4" v-if="data.telephone">
                  <a
                    :href="'tel:' + data.telephone"
                    class="btn btn-primary sv-button btn-sm w-100"
                  >
                    {{ callLabel }}
                  </a>
                </div>
                <div class="ml-4" v-if="data.emails">
                  <a
                    :href="'mailto:' + data.emails"
                    class="btn btn-primary sv-button btn-sm w-100"
                  >
                    {{ emailLabel }}
                  </a>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" lg="6">
            <a
              :href="
                'https://maps.google.com/maps?q=' +
                data.latitude +
                ',' +
                data.longitude
              "
              class="map-link map-link-usave"
              target="_blank"
            >
              <div class="static-map usave-location-map">
                <img
                  :src="
                    'https://maps.googleapis.com/maps/api/staticmap?center=' +
                    data.latitude +
                    ',' +
                    data.longitude +
                    '&amp;zoom=12&amp;size=1400x1400&amp;scale=1&amp;key=' +
                    googleKey +
                    '&amp;maptype=roadmap'
                  "
                  alt="Location Map"
                  loading="lazy"
                  class="w-100 border-radius-lg-10"
                />
              </div>
            </a>
          </b-col>
        </template>
      </template>
    </b-row>
  </b-col>
</template>

<script>
import axios from "axios";
import locationComponentInfo from "../mixins/location-component-info";

export default {
  name: "FmxOpeningHours",
  props: {
    acceptLanguage: {
      type: String,
      default: null,
    },
    fmxSorry: {
      type: String,
      default: null,
    },
    fmxLoading: {
      type: String,
      default: null,
    },
    fmxUrl: {
      type: String,
      default: null,
    },
    locationId: {
      type: String,
      default: null,
    },
    contactUsLabel: {
      type: String,
      default: null,
    },
    addressLabel: {
      type: String,
      default: null,
    },
    tableLabel: {
      type: String,
      default: null,
    },
    getDirectionsLabel: {
      type: String,
      default: null,
    },
    mapLink: {
      type: String,
      default: null,
    },
    locationOpen: {
      type: String,
      default: null,
    },
    locationClosed: {
      type: String,
      default: null,
    },
    emailLabel: {
      type: String,
      default: null,
    },
    callLabel: {
      type: String,
      default: null,
    },
  },
  mixins: [locationComponentInfo],
  data() {
    return {
      googleKey: window.googleMapsApiKey,
      loading: true,
      errored: false,
    };
  },
  mounted() {
    axios
      .get(
        this.fmxUrl + "/locations/" + this.locationId + "?include=opening_days",
        {
          headers: {
            "Accept-Language": this.acceptLanguage,
          },
        }
      )
      .then((response) => {
        // Data comes from the mixin
        this.locationInfo = response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
