import Vue from 'vue'
import Vuex from 'vuex'

import bookingFunnel from '@/store/booking-funnel'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    bookingFunnel
  }
})
