<template>
  <div>
    <section v-if="errored">
      <p class="m-0">{{ this.fmxSorry }}</p>
    </section>
    <template v-else>
      <div v-if="loading">
        <p class="m-0">{{ this.fmxLoading }}</p>
      </div>
      <template v-else>
        <section class="terms-header">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-11 col-lg-10">
                <select class="form-control terms-dropdown" name="selectCountry" @change="selectCountry(country)" v-model="selectedValue">
                  <option disabled selected value="0">{{ this.langSearchHere }}</option>
                  <template
                    v-for="country in data"
                  >
                    <optgroup v-if="country && country.isGroup" :key="country.id" :label="country.name">
                      <option
                        v-for="child in country.children"
                        :key="child.id"
                        :value="child"
                      >
                        {{ child.name }}
                      </option>
                    </optgroup>

                    <option
                      v-else
                      :key="country.id"
                      :value="country"
                    >
                      {{ country.name }}
                    </option>
                  </template>
                </select>
                <p class="m-0 mt-3 small"><a href="">{{ this.langReset }}</a></p>
              </div>
            </div>
          </div>
        </section>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FmxCountries',
  data () {
    return {
      country: null,
      data: null,
      loading: true,
      errored: false,
      selectedValue: 0
    }
  },
  props: {
    fmxSorry: {
      type: String,
      default: null
    },
    fmxLoading: {
      type: String,
      default: null
    },
    fmxUrl: {
      type: String,
      default: null
    },
    langSearchHere: {
      type: String,
      required: true
    },
    langReset: {
      type: String,
      required: true
    },
    urlCountry: {
      type: String,
      default: null
    }
  },
  mounted () {
    axios
      .get(this.fmxUrl + '/countries')
      .then(response => {
        const countries = response.data.data
        let validCountries = []

        countries.forEach((country, index) => {
          countries[index].isGroup = this.isGroup(country)
          countries[index].groupParentName = this.groupParentName(country)
          countries[index].children = []
        })

        countries.forEach((country, index) => {
          if (!country.isGroup) {
            validCountries.push(country)
          } else {
            const countryParent = {
              name: this.groupParentName(country),
              isGroup: true,
              id: country.id,
              children: []
            }

            if (validCountries.filter(element => element.name === countryParent.name).length === 0) {
              validCountries.push(countryParent)
            }

            validCountries.filter(element => element.name === countryParent.name)[0].children.push(country)
          }
        })

        validCountries = validCountries.sort((a, b) => a.name.localeCompare(b.name))

        this.data = validCountries
      })
      .catch(error => {
        console.log('there was an error:')
        console.log(error)
        this.errored = true
      })
      .finally(() => {
        this.loading = false

        if (this.urlCountry) {
          const filteredCountries = this.data.filter(country => country.slug === this.urlCountry)

          if (filteredCountries.length) {
            this.selectedValue = filteredCountries[0]

            this.selectCountry(filteredCountries[0])
          }
        }
      })
  },
  methods: {
    isGroup (country) {
      return country.iso_alpha2 === 'US'
    },
    groupParentName (country) {
      return country.iso_alpha2 === 'US' ? 'United States' : null
    },
    selectCountry (country) {
      country = this.selectedValue
      this.$emit('country-selected', country)
    }
  }
}
</script>
