import Vue from 'vue'
import Vuex, { mapActions } from 'vuex'
import store from '@/store'

import { BootstrapVue } from 'bootstrap-vue'

import Locations from '@/components/Locations'
import BookingFunnel from '@/components/BookingFunnel'
import SelectLanguageBookingFunnel from '@/components/SelectLanguageBookingFunnel'
import SubMenu from '@/components/SubMenu'
import FmxHero from '@/components/FmxHero'
import FmxHeroWithButtons from '@/components/FmxHeroWithButtons'
import FmxContact from '@/components/FmxContact'
import FmxOpeningHours from '@/components/FmxOpeningHours'
import FmxWrapper from '@/components/FmxWrapper'
import FmxCountries from '@/components/FmxCountries'
import FmxMap from '@/components/FmxMap'
import FmxLegal from '@/components/FmxLegal'

import VueFuse from 'vue-fuse'

// Import Fontawsome
import '@/utilities/fontawesome'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(VueFuse)

Vue.component('Locations', Locations)
Vue.component('BookingFunnel', BookingFunnel)
Vue.component('SelectLanguageBookingFunnel', SelectLanguageBookingFunnel)
Vue.component('SubMenu', SubMenu)
Vue.component('FmxHero', FmxHero)
Vue.component('FmxHeroWithButtons', FmxHeroWithButtons)
Vue.component('FmxContact', FmxContact)
Vue.component('FmxOpeningHours', FmxOpeningHours)
Vue.component('FmxWrapper', FmxWrapper)
Vue.component('FmxCountries', FmxCountries)
Vue.component('FmxMap', FmxMap)
Vue.component('FmxLegal', FmxLegal)

// Global vue translations variable
Vue.prototype.$lang = (val, choice = null) => window.lang(val, choice)

// Import bootstrap components - this needs to be filled in
// import "../vuejs/utilities/bootstrap.js";

new Vue({
  el: '#app',
  delimiters: ['${', '}'],
  methods: {
    ...mapActions({
      toggleVisible: 'bookingFunnel/toggleVisible'
    }),
    showModal () {
      if (this.$refs['modal-feature'] !== undefined) {
        setTimeout(() => {
          this.$refs['modal-feature'].show()
        }, 5000)
      }
    },
    hideModal () {
      if (this.$refs['modal-feature'] !== undefined) {
        this.$refs['modal-feature'].hide()
      }
    }
  },
  mounted () {
    this.showModal()
  },
  store
})
