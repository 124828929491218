export default {
  methods: {
    brandingImagesPath (path) {
      if (window.brand === 'usave') {
        return path.replace('/images/', '/images/brand/usave/')
      }

      return path
    },
    requireBrandingImagesPath (path) {
      this.brandingImagesPath(path)
    }
  }
}
