export default {
  data () {
    return {
      locationInfo: null
    }
  },
  computed: {
    data () {
      if (!this.locationInfo) {
        return {}
      }

      return this.locationInfo
    },
    locationFullAddress () {
      // Get the full address elements only if they are not null
      const addressArray = []

      if (this.data?.address_1) {
        addressArray.push(this.data.address_1)
      }

      if (this.data?.city) {
        addressArray.push(this.data.city)
      }

      if (this.data?.county) {
        addressArray.push(this.data.county)
      }

      if (this.data?.postcode) {
        addressArray.push(this.data.postcode)
      }

      return addressArray.filter(x => x.length > 0).join(', ')
    },
    openingDays () {
      return this.data?.opening_days?.data
    }
  }
}
