<template>
    <div class="ml-5">
        <div class="sub-navbar-toggler d-none d-lg-block" :class="visible ? null : 'isCollapsed'"
             :aria-expanded="visible ? 'true' : 'false'"
             aria-controls="collapse-sub-menu"
             @click="toggleVisible">
            <div class="sub-navbar-toggler-icon">
                <div></div>
            </div>
        </div>
        <b-collapse id="collapse-sub-menu" v-model="visible">
            <b-container fluid class="position-relative">
                <slot name="menu"></slot>
            </b-container>
        </b-collapse>
        <span class="d-none d-lg-block">
      <span :class="{'d-none':!visible}">
        <div class="sub-menu-wrapper">
          <div class="sub-menu-layer">
          </div>
        </div>
      </span>
    </span>
    </div>
</template>

<script>
import store from '@/store'
import { mapActions } from 'vuex'
export default {
  name: 'SubMenu',
  computed: {
    visible: {
      get () {
        return this.$store.state.bookingFunnel.visible
      },
      set () { }
    }
  },
  methods: {
    ...mapActions({
      toggleVisible: 'bookingFunnel/toggleVisible'
    })
  },
  store
}
</script>
