<template>
  <div class="naked-dropdown mr-lg-3">
    <img :src="brandingImagesPath('/images/header-flags/') + langFlagHandle + '.svg'" :alt="langTitle" class="featured-flag" v-model="langFlagHandle" loading="lazy">
    <b-dropdown id="dropdown-1" class="text-capitalize" :text="langTitle" v-model="langTitle" variant="none">
      <template v-if="franchiseEnglishLanguageVisibility" v-for="(language, key) in locationISO.locations">
        <b-dropdown-item
          v-if="language.visible && (domainExtension() === language.extension)"
          :key="key"
          @click="updateLanguage(language.iso); langTitle=langLocal; langFlagHandle=langLocalHandle;"
          :class="{'active':isLanguage(language.iso)} + ' text-capitalize'"
        >
          <img :src="brandingImagesPath('/images/header-flags/') + langLocalHandle + '.svg'" width="25" :alt="langLocal" loading="lazy">
          {{ langLocal }}
        </b-dropdown-item>
      </template>
      <template v-if="franchiseEnglishLanguageVisibility" v-for="(language, key) in locationISO.locations">
        <b-dropdown-item
          v-if="language.domain === 'com' && !franchiseEnglishLanguageRedirect"
          :key="key"
          @click="updateLanguage(language.iso); langTitle=langDefault; langFlagHandle=langDefaultHandle"
          :class="{'active':isLanguage(language.iso)} + ' text-capitalize'"
        >
          <img :src="brandingImagesPath('/images/header-flags/') + langDefaultHandle + '.svg'" width="25" :alt="langDefault" loading="lazy">
          {{ langDefault }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="language.domain === 'com' && franchiseEnglishLanguageRedirect"
          :href="defaultSiteUrl"
          :key="key"
          :class="{'active':isLanguage(language.iso)} + ' text-capitalize'"
        >
          <img :src="brandingImagesPath('/images/header-flags/') + langDefaultHandle + '.svg'" width="25" :alt="langDefault" loading="lazy">
          {{ langDefault }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
import store from '@/store'
import LocationISO from '@/data/locations.js'
import branding from '../mixins/branding'

export default {
  name: 'SelectLanguageBookingFunnel',
  mixings: [branding],
  data() {
    return {
      lang: null,
      locationISO: LocationISO,
      defaultSiteUrl: window.defaultSite,
      bookingSiteUrl: window.bookingSite
    }
  },
  mounted() {
    const storedLang = localStorage.getItem('lang')
    if (storedLang === null) {
      this.countryISO()
    } else {
      this.updateLanguage(storedLang)
    }
  },
  computed: {
    getUrl() {
      if (this.lang !== null) {
        // Added &pickup_location to the below, will only work on banner-with-booking component as that's where the location ID is passed to the component, if no value is set it'll just return null
        return this.bookingUrl() + '/search?iframe=true&language=' + this.lang + '&pickup_location=' + this.locationId
      }
      return this.bookingDefaultUrl()
    },
    currentLanguageName() {
      const lang = this.getLanguage(this.lang)
      if (lang.length) {
        return lang[0].name
      }
      // const lang = this.langLocal
      // return lang
      //
      return 'English'
    },
    urlLocation() {
      return window.location.host
    }
  },
  watch: {
    getUrl(val) {
      this.$store.state.bookingFunnel.language = val
    }
  },
  props: {
    environment: {
      type: String
    },
    domain: {
      type: String
    },
    extension: {
      type: String
    },
    langDefault: {
      type: String
    },
    langDefaultHandle: {
      type: String
    },
    langLocal: {
      type: String
    },
    langTitle: {
      type: String
    },
    langLocalHandle: {
      type: String
    },
    langFlagHandle: {
      type: String
    },
    franchiseEnglishLanguageVisibility: {
      type: String
    },
    franchiseEnglishLanguageRedirect: {
      type: String
    }
  },
  methods: {
    environmentStatus() {
      const environment = this.environment
      if (environment === 'dev') {
        return '-dev'
      } else if (environment === 'staging') {
        return '-staging'
      } else if (environment === 'production') {
        return ''
      } else {
        return 'cant find an environment to select: ' + environment
      }
    },
    domainName() {
      return this.domain
    },
    domainExtension() {
      return this.extension
    },
    bookingUrl() {
      const environment = this.environmentStatus()
      let booking_url = null;

      if (environment == 'dev') {
        const subdomain = 'http://booking.local'
        const host = this.domainName()
        const extension = this.domainExtension()
        //console.log('host:' + host)
        booking_url = (subdomain + host + extension);
      }
      else {
        const subdomain = 'https://booking'
        const environment = this.environmentStatus()
        const host = this.domainName()
        const extension = this.domainExtension()
        booking_url = (subdomain + environment + host + extension);
      }

      return booking_url;
    },
    bookingDefaultUrl() {
      return this.bookingSiteUrl + '/search?iframe=true&language=en'
    },
    isLanguage(iso) {
      return (iso === this.lang)
    },
    getLanguage(domain) {
      return this.locationISO.locations.filter((x) => x.iso === domain)
    },
    updateLanguage(iso) {
      this.lang = iso
      localStorage.setItem('lang', this.lang)
    },
    countryISO() {
      // Split url
      const urlSplit = this.urlLocation.split('.')
      const count = urlSplit.length
      if (count <= 1) {
        return ' '
      }
      // Get last item
      const lastItem = urlSplit.slice(-1)[0]

      // Search through locationISO using last item
      const location = this.locationISO.locations.filter(location => {
        return location.domain.toLowerCase() === lastItem
      })

      // Set location
      if (location.length) {
        this.lang = location[0].iso
        this.updateLanguage(this.lang)
      }
    }
  },
  store
}
</script>
