<template>
  <b-col cols="11" lg="8" class="contact-details d-flex" v-if="locationId">
    <b-row class="bg-overlay py-4 px-4 px-lg-5 mt-lg-auto no-gutters w-100">
      <section v-if="errored">
        <p class="m-0">{{ fmxSorry }}</p>
      </section>
      <template v-else>
        <div v-if="loading">
          <p class="m-0">{{ fmxLoading }}</p>
        </div>
        <template v-else>
          <b-col cols="12" lg="8" class="mb-5 mb-lg-0">
            <h4 class="mb-4" v-if="data.name" v-html="data.name"></h4>
            <p class="m-0 font-weight-bold" v-if="data.name">
              {{ addressLabel }}
            </p>
            <address class="m-0 small">
              <div v-if="data.address_1" v-html="data.address_1"></div>
              <div v-if="data.address_2" v-html="data.address_2"></div>
              <div v-if="data.address_3" v-html="data.address_3"></div>
              <div v-if="data.city" v-html="data.city"></div>
              <div v-if="data.county" v-html="data.county"></div>
              <div v-if="data.postcode" v-html="data.postcode"></div>
            </address>
          </b-col>
          <b-col cols="12" lg="4" class="my-lg-auto">
            <p class="mb-4">
              <a
                :href="mapLink"
                target="_blank"
                class="btn btn-primary btn-rounded btn-sm w-100"
                v-if="mapLink"
              >
                {{ getDirectionsLabel }}
              </a>
              <a
                :href="
                  'https://maps.google.com/maps?q=' +
                  data.latitude +
                  ',' +
                  data.longitude
                "
                target="_blank"
                class="btn btn-primary btn-rounded btn-sm w-100"
                v-else
              >
                {{ getDirectionsLabel }}
              </a>
            </p>
            <p class="mb-4" v-if="data.telephone">
              <a
                :href="'tel:' + data.telephone"
                class="btn btn-primary btn-rounded btn-sm w-100"
                v-html="data.telephone"
              ></a>
            </p>
            <p class="mb-4" v-if="data.emails">
              <a
                :href="'mailto:' + data.emails"
                class="btn btn-primary btn-rounded btn-sm w-100"
              >
                {{ emailLabel }}
              </a>
            </p>
            <p class="m-0" v-if="data.whatsappnumber">
              <a
                :href="
                  'https://wa.me/' + data.whatsappnumber.replace(/[^\d]/g, '')
                "
                class="
                  btn btn-primary btn-rounded btn-sm
                  w-100
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <font-awesome-icon
                  :icon="['fab', 'whatsapp']"
                  size="2x"
                  class="mr-3 overflow-visible"
                  style="margin: -50% 0"
                ></font-awesome-icon>
                WhatsApp Us
              </a>
            </p>
          </b-col>
        </template>
      </template>
    </b-row>
  </b-col>
</template>

<script>
import axios from "axios";

export default {
  name: "FmxHero",
  props: {
    acceptLanguage: {
      type: String,
      default: null,
    },
    fmxSorry: {
      type: String,
      default: null,
    },
    fmxLoading: {
      type: String,
      default: null,
    },
    fmxUrl: {
      type: String,
      default: null,
    },
    locationId: {
      type: String,
      default: null,
    },
    addressLabel: {
      type: String,
      default: null,
    },
    emailLabel: {
      type: String,
      default: null,
    },
    getDirectionsLabel: {
      type: String,
      default: null,
    },
    mapLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: null,
      loading: true,
      errored: false,
    };
  },
  mounted() {
    axios
      .get(this.fmxUrl + "/locations/" + this.locationId, {
        headers: {
          "Accept-Language": this.acceptLanguage,
        },
      })
      .then((response) => {
        this.data = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
