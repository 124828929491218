<template>
  <div class="locations" v-if="filteredList">
    <section class="location-list-header mb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-11 col-lg-10">
            <div class="custom-bs-select-wrap" id="locations-dropdown">
              <b-form-group
                id="fieldset-1"
                label-for="search-input"
                type="search"
                class="m-0"
              >
                <b-form-input id="search-input" v-model="search"
                              :placeholder="this.langSearchHere"></b-form-input>
              </b-form-group>

              <select class="form-control country-dropdown" v-model="selectedCountry"
                      @change="changeSelect">
                <option :value="null" selected>{{ defaultLocationString }}</option>
                <template v-for="(location,key) in initialLocations">
                  <option
                    v-if="locations && currentCountry && ((currentCountry.domain === 'com' || currentCountry.domain === 'test') || (location.domain === currentCountry.domain))"
                    :key="key"
                    :value="location.title"
                  >
                    {{ location.title }}
                  </option>
                </template>
              </select>
              <p class="m-0 mt-3 small"><a href="">{{ this.langReset }}</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-container fluid>
      <b-row class="justify-content-center">
        <b-col cols="11" lg="12">
          <div
            v-for="(location, key) in filteredList"
            :key="key"
            :class="locationClasses"
            :id="location.item.slug"
          >
            <div class="mb-5 text-center">
              <img :src="'/images/flags/'+location.item.slug+'.svg'" class="location-country-flag"
                   :alt="location.item.title" loading="lazy">
              <h2 v-if="currentCountry.iso === 'tr-TR'">
                {{ langCarHireIn }}
              </h2>
              <h2 v-else>
                {{ langCarHireIn }} {{ location.item.title }} {{ locationId}}
              </h2>
              <div class="px-lg-5" v-html="location.item.description"></div>
            </div>

            <slick
              class="locations-carousel"
              ref="slickCard"
              :options="slickOptions"
            >
              <country-card
                class="locations-carousel"
                v-for="(entry, eKey) in location.item.entries"
                :key="eKey"
                :locationId="entry.locationId"
                :title="entry.title"
                :telephoneLabel="langTelephone"
                :emailLabel="langEmail"
                :url="entry.url"
                :urlLabel="langViewLocation"
                :slug="location.item.slug"
              ></country-card>
            </slick>
          </div>
          <div v-if="!filteredList.length">
            <p class="text-center font-weight-bold" v-html="langSorry"></p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LocationISO from '@/data/locations.js'
import CountryCard from './CountryCard'
import Slick from 'vue-slick'

export default {
  name: 'Locations',
  components: {
    CountryCard,
    Slick
  },
  props: {
    locationId: {
      type: Number,
      default: null
    },
    locations: {
      type: String,
      required: true
    },
    langSearchHere: {
      type: String,
      required: true
    },
    langReset: {
      type: String,
      required: true
    },
    langCarHireIn: {
      type: String,
      required: true
    },
    langTelephone: {
      type: String,
      required: true
    },
    langEmail: {
      type: String,
      required: true
    },
    langViewLocation: {
      type: String,
      required: true
    },
    langSorry: {
      type: String,
      required: true
    },
    langGlobal: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      brand: window.brand,
      defaultSiteUrl: window.defaultSite,
      filteredList: null,
      selectedCountry: null,
      currentCountry: null,
      search: null,
      locationISO: LocationISO,
      defaultLocationString: this.langGlobal,
      slickOptions: {
        arrows: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        rows: 0,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              adaptiveHeight: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    initialLocations () {
      if (!this.locations) {
        return []
      }
      const locations = JSON.parse(this.locations)

      let newLocations = []
      for (const aLocation of locations) {
        const domain = this.findLocationByName(aLocation.title)
        newLocations.push({
          ...aLocation,
          domain: domain.length ? domain[0].domain : ''
        }
        )
      }

      newLocations.forEach((element, index) => {
        newLocations[index].titleRegular = element.title.replaceAll('Ğ', 'g')
          .replaceAll('Ü', 'u')
          .replaceAll('Ş', 's')
          .replaceAll('I', 'i')
          .replaceAll('İ', 'i')
          .replaceAll('Ö', 'o')
          .replaceAll('Ç', 'c')
          .replaceAll('ğ', 'g')
          .replaceAll('ü', 'u')
          .replaceAll('ş', 's')
          .replaceAll('ı', 'i')
          .replaceAll('ö', 'o')
          .replaceAll('ç', 'c')

        element.entries.forEach((entryElement, entryIndex) => {
          newLocations[index].entries[entryIndex].titleRegular = entryElement.title.replaceAll('Ğ', 'g')
            .replaceAll('Ü', 'u')
            .replaceAll('Ş', 's')
            .replaceAll('I', 'i')
            .replaceAll('İ', 'i')
            .replaceAll('Ö', 'o')
            .replaceAll('Ç', 'c')
            .replaceAll('ğ', 'g')
            .replaceAll('ü', 'u')
            .replaceAll('ş', 's')
            .replaceAll('ı', 'i')
            .replaceAll('ö', 'o')
            .replaceAll('ç', 'c')
        })
      })

      return newLocations
    },
    locationsArray () {
      if (!this.initialLocations) {
        return []
      }

      let locations = this.initialLocations

      if (this.selectedCountry) {
        locations = locations.filter(location => location.titleRegular === this.selectedCountry)
      }

      return locations
    },
    locationClasses () {
      if (this.brand === 'usave') {
        return 'country-row'
      }
      return 'country-row mb-4 mb-lg-5'
    },
    filterValue () {
      return this.search || this.selectedCountry || ''
    },
    urlLocation () {
      return window.location.host
    }
  },
  watch: {
    search () {
      this.filterList()
    },
    selectedCountry () {
      this.filterList()
      if (this.search !== null) {
        this.search = null
      }
    }
  },
  methods: {
    filterList () {
      const value = this.filterValue.replaceAll('Ğ', 'g')
        .replaceAll('Ü', 'u')
        .replaceAll('Ş', 's')
        .replaceAll('I', 'i')
        .replaceAll('İ', 'i')
        .replaceAll('Ö', 'o')
        .replaceAll('Ç', 'c')
        .replaceAll('ğ', 'g')
        .replaceAll('ü', 'u')
        .replaceAll('ş', 's')
        .replaceAll('ı', 'i')
        .replaceAll('ö', 'o')
        .replaceAll('ç', 'c')

      this.$search(
        value,
        this.locationsArray,
        {
          keys: ['titleRegular', 'entries.titleRegular'],
          includeMatches: true,
          minMatchCharLength: 1,
          includeScore: true,
          threshold: 0.1
        }
      ).then(results => {
        if (!this.filterValue.length || this.filterValue === 'Global') {
          this.filteredList = this.locationsArray.map((doc, idx) => ({
            item: doc,
            score: 1,
            refIndex: idx
          }))
        } else {
          if (results.length) {
            this.filteredList = this.replaceWithMatches(results)
          } else {
            this.filteredList = []
          }
        }

        this.reInit()
      })
    },
    replaceWithMatches (results) {
      const newResults = []
      results.forEach((country, index) => {
        let newResult = {} // Set default
        if (country.matches && country.matches[0].key !== 'titleRegular') { // Franchise Title found
          const franchises = []
          country.matches.forEach((franchise) => {
            const newFranchise = {
              title: country.item.entries[franchise.arrayIndex].title,
              telephone: country.item.entries[franchise.arrayIndex].telephone,
              email: country.item.entries[franchise.arrayIndex].email,
              url: country.item.entries[franchise.arrayIndex].url,
              slug: country.item.entries[franchise.arrayIndex].slug
            }
            franchises.push(newFranchise)
          })
          newResult = {
            item: {
              ...country.item,
              entries: franchises
            }
          }
        } else { // Country title found
          newResult = {
            item: {
              ...country.item
            }
          }
        }
        newResults.push(newResult)
      })
      return newResults
    },
    getDomain () {
      const urlSplit = this.urlLocation.split('.')
      const count = urlSplit.length
      if (count <= 1) {
        return ''
      }
      return urlSplit.slice(-1)[0]
    },

    findLocationByDomain (domain) {
      return this.locationISO.locations.filter(location => {
        return location.domain.toLowerCase() === domain
      })
    },
    findLocationByName (name) {
      return this.locationISO.locations.filter(location => {
        return location.name === name
      })
    },

    countryISO () {
      const domain = this.getDomain()
      const location = this.findLocationByDomain(domain)
      this.currentCountry = location[0]

      if (this.currentCountry) {
        if (this.currentCountry.name === this.defaultLocationString) {
          this.selectedCountry = null
        } else {
          this.selectedCountry = this.currentCountry.name
        }
      }
    },

    changeSelect () {
      if (this.currentCountry.domain === 'com' || this.currentCountry.domain === 'test') {
        return true
      }

      const selectedLocation = this.findLocationByName(this.selectedCountry)[0]

      if ((this.currentCountry.domain !== 'com') || (this.currentCountry.domain !== 'test') || !selectedLocation || selectedLocation.domain !== this.currentCountry.domain) {
        window.location.href = this.defaultSiteUrl
      }
    },
    reInit () {
      if (this.$refs.slickCard && !Array.isArray(this.$refs.slickCard)) {
        this.$refs.slickCard.destroy()
        this.$nextTick(() => {
          this.$refs.slickCard.create()
        })
      }
      if (this.$refs.slickCard && Array.isArray(this.$refs.slickCard)) {
        this.$refs.slickCard.forEach((slickCard) => {
          slickCard.destroy()
          this.$nextTick(() => {
            slickCard.create()
          })
        })
      }
    }
  },
  mounted () {
    this.filterList()
    this.countryISO()
  }
}
</script>
