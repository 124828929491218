<template>
  <b-card
    tag="article"
    class="location-card"
  >
    <div class="d-lg-flex flex-lg-column h-100">
      <h2 class="h3 mb-4">{{ title }}</h2>
<!--      <ul class="list-unstyled">-->
<!--        <li v-if="data.telephone">-->
<!--          {{ telephoneLabel }}-->
<!--          <a class="grey-link" :href="'tel:'+data.telephone">{{ data.telephone }}</a>-->
<!--        </li>-->
<!--        <li v-if="data.emails[0]">-->
<!--          {{ emailLabel }}-->
<!--          <a class="grey-link" :href="'mailto: '+data.emails[0]">-->
<!--            {{ data.emails[0] }}-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
      <a :href="url" class="btn btn-primary btn-rounded mt-lg-auto">
        {{ urlLabel }}
      </a>
      <img :src="'/images/country-maps/'+slug+'.svg'"
           class="country-map-card"
           :alt="title"
           loading="lazy">
    </div>
  </b-card>

</template>

<!--<div>-->
<!--  <b-card v-if="errored"-->
<!--          tag="article"-->
<!--          class="location-card"-->
<!--  >-->
<!--    <div class="d-lg-flex flex-lg-column h-100">-->
<!--      <h2 class="h3 mb-3">We're sorry, we're not able to retrieve this information at the moment, please try back-->
<!--        later.</h2>-->
<!--    </div>-->
<!--  </b-card>-->
<!--  <template v-else>-->
<!--    <div v-if="loading">-->
<!--      <p class="m-0">Loading...</p>-->
<!--    </div>-->
<!--    <template v-else>-->
<!--      <b-card-->
<!--        tag="article"-->
<!--        class="location-card"-->
<!--      >-->
<!--        <div class="d-lg-flex flex-lg-column h-100">-->
<!--          <h2 class="h3 mb-3">{{ title }} - {{ locationId }}</h2>-->
<!--          <ul class="list-unstyled">-->
<!--            <li v-if="data.telephone">-->
<!--              {{ telephoneLabel }}-->
<!--              <a class="grey-link" :href="'tel:'+data.telephone">{{ data.telephone }}</a>-->
<!--            </li>-->
<!--            <li v-if="data.emails[0]">-->
<!--              {{ emailLabel }}-->
<!--              <a class="grey-link" :href="'mailto: '+data.emails[0]">-->
<!--                {{ data.emails[0] }}-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <a :href="url" class="btn btn-primary btn-rounded mt-lg-auto">-->
<!--            {{ urlLabel }}-->
<!--          </a>-->
<!--          <img :src="'/images/country-maps/'+slug+'.svg'"-->
<!--               class="country-map-card"-->
<!--               :alt="title">-->
<!--        </div>-->
<!--      </b-card>-->
<!--    </template>-->
<!--  </template>-->
<!--</div>-->

<script>
// import axios from 'axios'


export default {
  name: 'Locations',
  props: {
    locationId: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    telephoneLabel: {
      type: String,
      default: null
    },
    emailLabel: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    urlLabel: {
      type: String,
      default: null
    },
    slug: {
      type: String,
      default: null
    }
  },
  // data() {
  //   return {
  //     data: null,
  //     loading: true,
  //     errored: false
  //   }
  // },
  // mounted() {
  //   axios
  //      + this.locationId
  //     .get('https://api.greenmotion.com/api/locations/11')
  //     .then(response => {
  //       this.data = response.data.data
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       this.errored = true
  //     })
  //     .finally(() => this.loading = false)
  // }
}
</script>
