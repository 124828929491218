export default {
  namespaced: true,
  state: {
    // Default funnel language if all else fails
    language: window.bookingSite + '/search?iframe=true&language=en',
    searchURL: window.searchURL,
    visible: false
  },
  getters: {
    getSearchUrl (state) {
      return state.searchURL
    },
    getLanguage (state) {
      return state.language
    },
    getVisible (state) {
      return state.visible
    }
  },
  mutations: {
    toggle (state, payload) {
      state.visible = payload
    }
  },
  actions: {
    toggleVisible (state) {
      state.commit('toggle', !state.state.visible)
    }
  }
}
