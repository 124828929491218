<template>
  <b-col cols="11" lg="4" class="mb-5 mb-lg-0">
    <div class="booking-form booking-form-container-usave">
      <!-- Added location ID to the below src, this will only work on banner-with-booking component as that's where the location ID is passed to the component, if no value is set it'll just return null -->
      <iframe
        id="iframe"
        :src="getUrl + '&pickup_location=' + this.locationId"
        :title="title"
        sandbox="allow-scripts allow-forms allow-top-navigation allow-same-origin"
        frameborder="0"
        class="shadow border-radius-10"
        style="height: 650px;"
        scrolling="no"
        onLoad="handleIframeLoad()"
      >
      </iframe>
    </div>
  </b-col>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'BookingFunnel',
  data () {
    return {
      brand: window.brand,
      source: ''
    }
  },
  computed: {
    ...mapGetters({
      searchUrl: 'bookingFunnel/getSearchUrl'
    }),
    getUrl () {
      let url = this.searchUrl

      const urlParams = new URLSearchParams(window.location.search)

      if (urlParams.toString()) {
        url = url + '&' + urlParams
      }

      return url
    },
    title () {
      if (this.brand === 'usave') {
        return 'U-Save Booking'
      }

      return 'Green Motion Booking'
    }
  },
  props: {
    locationId: {
      type: String,
      default: null
    },
  },
  store
}
</script>
