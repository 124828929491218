<template>
  <b-col cols="11" lg="8" class="contact-details">
    <b-row class="justify-content-between align-items-center mb-5">
      <section v-if="errored">
        <p class="m-0">{{ fmxSorry }}</p>
      </section>
      <template v-else>
        <div v-if="loading">
          <p class="m-0">{{ fmxLoading }}</p>
        </div>
        <template v-else>
          <b-col cols="11" lg="12" class="mb-5" v-if="contactUsLabel">
            <h2 class="promo-title text-center m-0">{{ contactUsLabel }}</h2>
          </b-col>
          <b-col cols="12" lg="6" class="mb-3 mb-lg-0">
            <h2 class="mb-4" v-if="addressLabel">{{ addressLabel }}</h2>
            <address class="mb-5">
              <div v-if="data.address_1" v-html="data.address_1"></div>
              <div v-if="data.address_2" v-html="data.address_2"></div>
              <div v-if="data.address_3" v-html="data.address_3"></div>
              <div v-if="data.city" v-html="data.city"></div>
              <div v-if="data.county" v-html="data.county"></div>
              <div v-if="data.postcode" v-html="data.postcode"></div>
            </address>
            <p class="mb-5">
              <a
                :href="mapLink"
                target="_blank"
                class="get-directions-link"
                v-if="mapLink"
              >
                <font-awesome-icon
                  :icon="['fal', 'map-marker']"
                  size="2x"
                  class="float-left mr-3"
                ></font-awesome-icon>
                {{ getDirectionsLabel }}
              </a>
              <a
                :href="
                  'https://maps.google.com/maps?q=' +
                  data.latitude +
                  ',' +
                  data.longitude
                "
                target="_blank"
                class="get-directions-link"
                v-else
              >
                <font-awesome-icon
                  :icon="['fal', 'map-marker']"
                  size="2x"
                  class="float-left mr-3"
                ></font-awesome-icon>
                {{ getDirectionsLabel }}
              </a>
            </p>
            <p class="mb-5" v-if="data.telephone">
              <a :href="'tel:' + data.telephone">
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  size="2x"
                  class="float-left mr-3"
                ></font-awesome-icon>
                {{ data.telephone }}
              </a>
            </p>
            <p class="mb-5" v-if="data.emails[0]">
              <a :href="'mailto:' + data.emails[0]">
                <font-awesome-icon
                  :icon="['fal', 'envelope']"
                  size="2x"
                  class="float-left mr-3"
                ></font-awesome-icon>
                {{ data.emails[0] }}
              </a>
            </p>
            <p class="m-0" v-if="data.whatsappnumber">
              <a
                :href="
                  'https://wa.me/' + data.whatsappnumber.replace(/[^\d]/g, '')
                "
                class="d-flex align-items-center"
              >
                <font-awesome-icon
                  :icon="['fab', 'whatsapp']"
                  size="2x"
                  class="float-left mr-3"
                ></font-awesome-icon>
                WhatsApp Us
              </a>
            </p>
          </b-col>
          <b-col cols="12" lg="6" class="content-green">
            <h3 class="h2 mb-4" v-if="tableLabel">{{ tableLabel }}</h3>
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tr v-for="day in data.opening_days.data" :key="day">
                  <td v-html="day.name"></td>
                  <td
                    class="text-right"
                    v-html="locationOpen"
                    v-if="day.is_24h === 1"
                  ></td>
                  <td
                    class="text-right"
                    v-html="locationClosed"
                    v-else-if="day.is_closed === 1"
                  ></td>
                  <td
                    class="text-right"
                    v-html="
                      new Date('1970-01-01 ' + day.open).toLocaleTimeString('en', {
                        hour12: acceptLanguage === 'en-US',
                        hour: 'numeric',
                        minute: 'numeric',
                      }) +
                      ' - ' +
                      new Date('1970-01-01 ' + day.close).toLocaleTimeString('en', {
                        hour12: acceptLanguage === 'en-US',
                        hour: 'numeric',
                        minute: 'numeric',
                      })"
                    v-else
                  ></td>
                </tr>
              </table>
            </div>
          </b-col>
        </template>
      </template>
    </b-row>
  </b-col>
</template>

<script>
import axios from "axios";

export default {
  name: "FmxContact",
  props: {
    acceptLanguage: {
      type: String,
      default: null,
    },
    fmxSorry: {
      type: String,
      default: null,
    },
    fmxLoading: {
      type: String,
      default: null,
    },
    fmxUrl: {
      type: String,
      default: null,
    },
    locationId: {
      type: String,
      default: null,
    },
    contactUsLabel: {
      type: String,
      default: null,
    },
    addressLabel: {
      type: String,
      default: null,
    },
    tableLabel: {
      type: String,
      default: null,
    },
    getDirectionsLabel: {
      type: String,
      default: null,
    },
    mapLink: {
      type: String,
      default: null,
    },
    locationOpen: {
      type: String,
      default: null,
    },
    locationClosed: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: null,
      loading: true,
      errored: false,
    };
  },
  mounted() {
    axios
      .get(
        this.fmxUrl + "/locations/" + this.locationId + "?include=opening_days",
        {
          headers: {
            "Accept-Language": this.acceptLanguage,
          },
        }
      )
      .then((response) => {
        this.data = response.data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
};
</script>
