<template>
  <b-col>
    <b-row>
      <section v-if="errored">
        <p class="m-0">{{ fmxSorry }}</p>
      </section>
      <template v-else>
        <div v-if="loading">
          <p class="m-0">{{ fmxLoading }}</p>
        </div>
        <template v-else>
          <b-col cols="11" lg="12" class="mb-5" v-if="findUsLabel">
            <h2 class="promo-title text-center mb-0">{{ findUsLabel }}</h2>
          </b-col>
          <!-- if franchise load Craft collection details -->
          <b-col cols="11" lg="6" class="my-auto" v-if="collectionDetails">
              <slot name="collection-details"></slot>
          </b-col>
          <!-- else load FMX collection details (international -->
          <b-col cols="11" lg="6" class="my-auto" v-else v-html="data.collection_details"></b-col>
          <b-col cols="11" lg="6" class="p-0 px-lg-5">
            <a :href="'https://maps.google.com/maps?q=' + data.latitude +',' + data.longitude" class="map-link"
               target="_blank">
              <div class="static-map">
                <img
                  :src="'https://maps.googleapis.com/maps/api/staticmap?center=' + data.latitude + ',' + data.longitude + '&amp;zoom=12&amp;size=1400x1400&amp;scale=1&amp;key=AIzaSyCfXiVfRRDkEqTd-MV7CJOQvIW-6i3d_pU&amp;maptype=roadmap'"
                  alt="Location Map" loading="lazy" class="w-100 border-radius-lg-10">
              </div>
            </a>
          </b-col>
        </template>
      </template>
    </b-row>
  </b-col>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FmxMap',
  props: {
    fmxSorry: {
      type: String,
      default: null
    },
    fmxLoading: {
      type: String,
      default: null
    },
    fmxUrl: {
      type: String,
      default: null
    },
    locationId: {
      type: String,
      default: null
    },
    findUsLabel: {
      type: String,
      default: null
    },
    collectionDetails: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      data: null,
      loading: true,
      errored: false
    }
  },
  mounted() {
    axios
      .get(this.fmxUrl + '/locations/' + this.locationId)
      .then(response => {
        this.data = response.data.data
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.loading = false)
  }
}
</script>
