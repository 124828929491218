<template>
  <div>
    <fmx-countries
      @country-selected="countrySelected"
      :fmx-sorry="this.fmxSorry"
      :fmx-loading="this.fmxLoading"
      :fmx-url="this.fmxUrl"
      :lang-reset="this.langReset"
      :lang-search-here="this.langSearchHere"
      :url-country="this.urlCountry"
    >
    </fmx-countries>
    <b-container>
      <b-col cols="11" lg="12">
        <b-row>
          <fmx-legal
            :country="selectedCountry"
            :fmx-sorry="this.fmxSorry"
            :fmx-loading="this.fmxLoading"
            :fmx-url="this.fmxUrl"
          >
          </fmx-legal>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'FmxWrapper',
  data () {
    return {
      selectedCountry: null
    }
  },
  props: {
    fmxSorry: {
      type: String,
      default: null
    },
    fmxLoading: {
      type: String,
      default: null
    },
    fmxUrl: {
      type: String,
      default: null
    },
    langSearchHere: {
      type: String,
      required: true
    },
    langReset: {
      type: String,
      required: true
    },
    urlCountry: {
      type: String,
      default: null
    }
  },
  methods: {
    countrySelected (country) {
      this.selectedCountry = country
    }
  }
}
</script>
