<template>
  <div class="d-flex flex-column justify-content-between w-100 h-100 flex-1">
    <div class="d-flex flex-column ml-5" v-if="!loading">
      <template v-if="!locationName">
        <span class="sv-heading-text text-uppercase">
          {{ data.name }}
        </span>
        <span class="sv-headline-text text-uppercase">
          {{ subname }} {{ locationAbbreviation }}
        </span>
      </template>

      <template v-else>
        <span class="sv-heading-text text-uppercase">
          {{ locationName }}
        </span>
        <span class="sv-headline-text text-uppercase">
          {{ locationSubname }}
        </span>
      </template>
    </div>

    <div class="ml-5">
        <p class="font-weight-bold text-white mb-3" v-if="data.address_1">
          <span>{{ locationFullAddress }}</span>
        </p>

        <div class="align-items-center d-flex">
          <div>
            <a
              :href="mapLink"
              target="_blank"
              class="btn btn-primary btn-rounded btn-sm w-100"
              v-if="mapLink"
            >
              {{ getDirectionsLabel }}
            </a>
            <a
              :href="
                'https://maps.google.com/maps?q=' +
                data.latitude +
                ',' +
                data.longitude
              "
              target="_blank"
              class="btn btn-primary sv-button btn-sm w-100"
              v-else
            >
              {{ getDirectionsLabel }}
            </a>
          </div>
          <div class="ml-4" v-if="data.telephone">
            <a
              :href="'tel:' + data.telephone"
              class="btn btn-primary sv-button btn-sm w-100"
            >
              {{ callLabel }}
            </a>
          </div>
          <div class="ml-4" v-if="data.emails">
            <a
              :href="'mailto:' + data.emails"
              class="btn btn-primary sv-button btn-sm w-100"
            >
              {{ emailLabel }}
            </a>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import locationComponentInfo from '../mixins/location-component-info'

export default {
  name: 'FmxHeroWithButtons',
  props: {
    fmxSorry: {
      type: String,
      default: null
    },
    fmxLoading: {
      type: String,
      default: null
    },
    fmxUrl: {
      type: String,
      default: null
    },
    locationId: {
      type: String,
      default: null
    },
    addressLabel: {
      type: String,
      default: null
    },
    emailLabel: {
      type: String,
      default: null
    },
    callLabel: {
      type: String,
      default: null
    },
    getDirectionsLabel: {
      type: String,
      default: null
    },
    mapLink: {
      type: String,
      default: null
    },
    locationName: {
      type: String,
      default: null
    },
    locationSubname: {
      type: String,
      default: null
    }
  },
  mixins: [locationComponentInfo],
  data () {
    return {
      loading: true,
      errored: false
    }
  },
  computed: {
    locationAbbreviation () {
      return this.data.iata ? `(${this.data.iata})` : ''
    },
    subname () {
      return this.data.is_airport ? 'Airport' : ''
    }
  },
  mounted () {
    axios
      .get(this.fmxUrl + '/locations/' + this.locationId)
      .then((response) => {
        // Data comes from the mixin
        this.locationInfo = response.data.data
      })
      .catch((error) => {
        console.log(error)
        this.errored = true
      })
      .finally(() => (this.loading = false))
  }
}
</script>
