var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"contact-details",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"align-items-stretch justify-content-between mb-5"},[(_vm.errored)?_c('section',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.fmxSorry))])]):[(_vm.loading)?_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.fmxLoading))])]):[_c('b-col',{staticClass:"d-flex flex-column mb-3 mb-lg-0 px-5",attrs:{"cols":"12","lg":"6"}},[(_vm.tableLabel)?_c('h2',{staticClass:"display-4 mb-5 text-center text-lg-left"},[_vm._v("\n            "+_vm._s(_vm.tableLabel)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',[_c('table',{staticClass:"table-borderless mb-0 w-100"},_vm._l((_vm.openingDays),function(day){return _c('tr',{key:day.id},[_c('td',[_c('div',{staticClass:"pr-5 font-2rem"},[_vm._v("\n                    "+_vm._s(day.name)+"\n                  ")])]),_vm._v(" "),(day.is_24h === 1)?_c('td',{staticClass:"font-weight-bold text-right font-2rem",domProps:{"innerHTML":_vm._s(_vm.locationOpen)}}):(day.is_closed === 1)?_c('td',{staticClass:"font-weight-bold text-right font-2rem",domProps:{"innerHTML":_vm._s(_vm.locationClosed)}}):_c('td',{staticClass:"font-weight-bold text-right font-2rem",domProps:{"innerHTML":_vm._s(
                    new Date('1970-01-01 ' + day.open).toLocaleTimeString('en', {
                      hour12: _vm.acceptLanguage === 'en-US',
                      hour: 'numeric',
                      minute: 'numeric',
                    }) +
                    ' - ' +
                    new Date('1970-01-01 ' + day.close).toLocaleTimeString('en', {
                      hour12: _vm.acceptLanguage === 'en-US',
                      hour: 'numeric',
                      minute: 'numeric',
                    }))}})])}),0)]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-1 flex-column h-100 justify-content-end"},[(_vm.data.address_1)?_c('p',{staticClass:"font-weight-bold mt-5 mb-3"},[_c('span',[_vm._v(_vm._s(_vm.locationFullAddress))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"align-items-center d-flex"},[_c('div',[(_vm.mapLink)?_c('a',{staticClass:"btn btn-primary btn-rounded btn-sm w-100",attrs:{"href":_vm.mapLink,"target":"_blank"}},[_vm._v("\n                  "+_vm._s(_vm.getDirectionsLabel)+"\n                ")]):_c('a',{staticClass:"btn btn-primary sv-button btn-sm w-100",attrs:{"href":'https://maps.google.com/maps?q=' +
                    _vm.data.latitude +
                    ',' +
                    _vm.data.longitude,"target":"_blank"}},[_vm._v("\n                  "+_vm._s(_vm.getDirectionsLabel)+"\n                ")])]),_vm._v(" "),(_vm.data.telephone)?_c('div',{staticClass:"ml-4"},[_c('a',{staticClass:"btn btn-primary sv-button btn-sm w-100",attrs:{"href":'tel:' + _vm.data.telephone}},[_vm._v("\n                  "+_vm._s(_vm.callLabel)+"\n                ")])]):_vm._e(),_vm._v(" "),(_vm.data.emails)?_c('div',{staticClass:"ml-4"},[_c('a',{staticClass:"btn btn-primary sv-button btn-sm w-100",attrs:{"href":'mailto:' + _vm.data.emails}},[_vm._v("\n                  "+_vm._s(_vm.emailLabel)+"\n                ")])]):_vm._e()])])]),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('a',{staticClass:"map-link map-link-usave",attrs:{"href":'https://maps.google.com/maps?q=' +
              _vm.data.latitude +
              ',' +
              _vm.data.longitude,"target":"_blank"}},[_c('div',{staticClass:"static-map usave-location-map"},[_c('img',{staticClass:"w-100 border-radius-lg-10",attrs:{"src":'https://maps.googleapis.com/maps/api/staticmap?center=' +
                  _vm.data.latitude +
                  ',' +
                  _vm.data.longitude +
                  '&zoom=12&size=1400x1400&scale=1&key=' +
                  _vm.googleKey +
                  '&maptype=roadmap',"alt":"Location Map","loading":"lazy"}})])])])]]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }