var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"contact-details",attrs:{"cols":"11","lg":"8"}},[_c('b-row',{staticClass:"justify-content-between align-items-center mb-5"},[(_vm.errored)?_c('section',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.fmxSorry))])]):[(_vm.loading)?_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.fmxLoading))])]):[(_vm.contactUsLabel)?_c('b-col',{staticClass:"mb-5",attrs:{"cols":"11","lg":"12"}},[_c('h2',{staticClass:"promo-title text-center m-0"},[_vm._v(_vm._s(_vm.contactUsLabel))])]):_vm._e(),_vm._v(" "),_c('b-col',{staticClass:"mb-3 mb-lg-0",attrs:{"cols":"12","lg":"6"}},[(_vm.addressLabel)?_c('h2',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.addressLabel))]):_vm._e(),_vm._v(" "),_c('address',{staticClass:"mb-5"},[(_vm.data.address_1)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.address_1)}}):_vm._e(),_vm._v(" "),(_vm.data.address_2)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.address_2)}}):_vm._e(),_vm._v(" "),(_vm.data.address_3)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.address_3)}}):_vm._e(),_vm._v(" "),(_vm.data.city)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.city)}}):_vm._e(),_vm._v(" "),(_vm.data.county)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.county)}}):_vm._e(),_vm._v(" "),(_vm.data.postcode)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.postcode)}}):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"mb-5"},[(_vm.mapLink)?_c('a',{staticClass:"get-directions-link",attrs:{"href":_vm.mapLink,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"float-left mr-3",attrs:{"icon":['fal', 'map-marker'],"size":"2x"}}),_vm._v("\n              "+_vm._s(_vm.getDirectionsLabel)+"\n            ")],1):_c('a',{staticClass:"get-directions-link",attrs:{"href":'https://maps.google.com/maps?q=' +
                _vm.data.latitude +
                ',' +
                _vm.data.longitude,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"float-left mr-3",attrs:{"icon":['fal', 'map-marker'],"size":"2x"}}),_vm._v("\n              "+_vm._s(_vm.getDirectionsLabel)+"\n            ")],1)]),_vm._v(" "),(_vm.data.telephone)?_c('p',{staticClass:"mb-5"},[_c('a',{attrs:{"href":'tel:' + _vm.data.telephone}},[_c('font-awesome-icon',{staticClass:"float-left mr-3",attrs:{"icon":['fal', 'phone'],"size":"2x"}}),_vm._v("\n              "+_vm._s(_vm.data.telephone)+"\n            ")],1)]):_vm._e(),_vm._v(" "),(_vm.data.emails[0])?_c('p',{staticClass:"mb-5"},[_c('a',{attrs:{"href":'mailto:' + _vm.data.emails[0]}},[_c('font-awesome-icon',{staticClass:"float-left mr-3",attrs:{"icon":['fal', 'envelope'],"size":"2x"}}),_vm._v("\n              "+_vm._s(_vm.data.emails[0])+"\n            ")],1)]):_vm._e(),_vm._v(" "),(_vm.data.whatsappnumber)?_c('p',{staticClass:"m-0"},[_c('a',{staticClass:"d-flex align-items-center",attrs:{"href":'https://wa.me/' + _vm.data.whatsappnumber.replace(/[^\d]/g, '')}},[_c('font-awesome-icon',{staticClass:"float-left mr-3",attrs:{"icon":['fab', 'whatsapp'],"size":"2x"}}),_vm._v("\n              WhatsApp Us\n            ")],1)]):_vm._e()]),_vm._v(" "),_c('b-col',{staticClass:"content-green",attrs:{"cols":"12","lg":"6"}},[(_vm.tableLabel)?_c('h3',{staticClass:"h2 mb-4"},[_vm._v(_vm._s(_vm.tableLabel))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless mb-0"},_vm._l((_vm.data.opening_days.data),function(day){return _c('tr',{key:day},[_c('td',{domProps:{"innerHTML":_vm._s(day.name)}}),_vm._v(" "),(day.is_24h === 1)?_c('td',{staticClass:"text-right",domProps:{"innerHTML":_vm._s(_vm.locationOpen)}}):(day.is_closed === 1)?_c('td',{staticClass:"text-right",domProps:{"innerHTML":_vm._s(_vm.locationClosed)}}):_c('td',{staticClass:"text-right",domProps:{"innerHTML":_vm._s(
                    new Date('1970-01-01 ' + day.open).toLocaleTimeString('en', {
                      hour12: _vm.acceptLanguage === 'en-US',
                      hour: 'numeric',
                      minute: 'numeric',
                    }) +
                    ' - ' +
                    new Date('1970-01-01 ' + day.close).toLocaleTimeString('en', {
                      hour12: _vm.acceptLanguage === 'en-US',
                      hour: 'numeric',
                      minute: 'numeric',
                    }))}})])}),0)])])]]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }