<template>
  <b-col cols="11" lg="12">
    <section v-if="errored">
      <p class="m-0">{{ this.fmxSorry }}</p>
    </section>
    <template v-else>
      <div v-if="loading">
        <!--<p class="m-0">{{ this.fmxLoading }}</p>-->
      </div>
      <template v-else>
        <div v-if="data.rendered_conditions" class="py-5">
          <h1 class="promo-title text-center mb-5">Terms & Conditions</h1>
          <div v-html="data.rendered_conditions"></div>
        </div>
      </template>
    </template>
  </b-col>
</template>

<script>
import axios from 'axios'

export default {
  name: 'FmxLegal',
  props: {
    fmxSorry: {
      type: String,
      default: null
    },
    fmxLoading: {
      type: String,
      default: null
    },
    fmxUrl: {
      type: String,
      default: null
    },
    country: Object,
  },
  data() {
    return {
      data: null,
      loading: true,
      errored: false
    }
  },
  mounted() {
    // axios
    //   .get('https://api.greenmotion.com/api/terms-and-conditions/?country_id=38904')
    //   .then(response => {
    //     this.data = response.data.data
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     this.errored = true
    //   })
    //   .finally(() => this.loading = false)
  },
  watch: {
    country(country) {
      axios
        .get(this.fmxUrl + `/terms-and-conditions/?country_id=${country.id}`)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
