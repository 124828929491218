import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'

// import { fab } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faTimes,
  faQuoteLeft,
  faQuoteRight,
  faCars,
  faTire,
  faPencil,
  faFaucet,
  faCarWash,
  faStoreAlt,
  faRecycle,
  faLightbulbOn,
  faWindTurbine,
  faGlobeEurope,
  faGlobeAsia,
  faGlobeAmericas,
  faGlobeAfrica,
  faPhoneLaptop,
  faUserHeadset,
  faPoundSign,
  faLiraSign,
  faEuroSign,
  faDollarSign,
  faUsers,
  faUserChart,
  faLaptopCode,
  faDownload,
  faMapMarker,
  faPhone,
  faEnvelope,
  faHashtag,
  faPaperPlane,
  faHandHoldingSeedling,
  faTreeAlt,
  faHeart,
  faLongArrowUp,
  faGraduationCap,
  faCreditCard,
  faCalculatorAlt,
  faIdCard,
  faUserTie,
  faComments,
  faTemperatureFrigid,
  faTemperatureHigh,
  faThermometerHalf,
  faThumbsUp,
  faCar,
  faGasPump,
  faArrowAltLeft,
  faArrowAltRight,
  faPlaneDeparture,
  faPlaneArrival,
  faChevronLeft,
  faChevronRight,
  faHouse,
  faQuestion
} from '@fortawesome/pro-light-svg-icons'

import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faPinterestP,
  faInstagram,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'

import {
  faStar
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTimes,
  faQuoteLeft,
  faQuoteRight,
  faCars,
  faTire,
  faPencil,
  faFaucet,
  faCarWash,
  faStoreAlt,
  faRecycle,
  faLightbulbOn,
  faWindTurbine,
  faGlobeEurope,
  faGlobeAsia,
  faGlobeAmericas,
  faGlobeAfrica,
  faPhoneLaptop,
  faUserHeadset,
  faPoundSign,
  faLiraSign,
  faEuroSign,
  faDollarSign,
  faUsers,
  faUserChart,
  faLaptopCode,
  faDownload,
  faMapMarker,
  faPhone,
  faEnvelope,
  faHashtag,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram,
  faPinterestP,
  faWhatsapp,
  faPaperPlane,
  faStar,
  faHandHoldingSeedling,
  faTreeAlt,
  faHeart,
  faLongArrowUp,
  faGraduationCap,
  faCreditCard,
  faCalculatorAlt,
  faIdCard,
  faUserTie,
  faComments,
  faTemperatureFrigid,
  faTemperatureHigh,
  faThermometerHalf,
  faThumbsUp,
  faCar,
  faGasPump,
  faArrowAltLeft,
  faArrowAltRight,
  faPlaneDeparture,
  faPlaneArrival,
  faChevronLeft,
  faChevronRight,
  faHouse,
  faQuestion
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
